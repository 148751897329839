<template>
  <div>
    <div class="welcome-content">
      <h1 class="title">
        <span class="gradient-text">UDhacks</span> for
        <br />
        <span class="brand-name">{{ videogame.title }}</span>
      </h1>
      <div class="description-wrapper">
        <p class="description">
          Unlock a diverse selection of free, secure, and
          <span class="bolded">undetected</span> hacks for
          {{ videogame.title }}.
          {{ videogame.pagetext }}
        </p>
        <div class="border-line"></div>
      </div>
    </div>
    <section ref="cheatSection" class="cheat-section">
      <cheatcard
        v-for="(cheat, index) in cheats"
        :key="index"
        :cheat="cheat"
        :videogameId="videogame.id"
      />
    </section>
  </div>
</template>

<script>
import Cheatcard from "../components/Cheatcard.vue";
import data from "../data/cheatdata";
import VideogameData from "../data/videogame";

export default {
  components: { Cheatcard },
  data() {
    return {
      cheats: [],
      videogame: null,
    };
  },
  created() {
    const urlId = this.$route.params.id;

    const videogame = VideogameData.videogames.find((videogame) => {
      return videogame.id === Number(urlId);
    });

    this.videogame = videogame;

    const cheatData = data.cheats.find((cheat) => {
      return cheat.id === Number(urlId);
    });

    if (!cheatData) {
      this.$router.push("/cheat");
      return;
    }

    this.cheats = cheatData.cheats;
  },
};
</script>

<style scoped>
.cheat-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 115px;
}

@media (max-width: 1200px) {
  .cheat-section {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 115px;
  }
}

@media (max-width: 992px) {
  .cheat-section {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 115px;
  }
}

@media (max-width: 768px) {
  .cheat-section {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 115px;
  }
}

@media (max-width: 480px) {
  .cheat-section {
    grid-template-columns: 1fr;
    margin-top: 65px;
  }
}

.title {
  font-size: 45px;
  font-weight: 800;
}

.gradient-text {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  font-weight: 900;
}

.brand-name {
  font-weight: 900;
}

.welcome-content {
  text-align: center;
  margin-top: 50px;
}

.description {
  font-size: 18px;
  margin-top: 10px;
  line-height: 1.6;
  color: #555;
  max-width: 680px;
  margin: 20px auto;
  font-weight: 500;
  padding: 10px;
}

.bolded {
  font-weight: bold;
  color: black;
}

.description-wrapper {
  display: inline-block; /* Make the wrapper fit the content width */
  position: relative;
}

.border-line {
  width: 100%; /* Match the width of the parent (which is the width of the paragraph) */
  height: 2px; /* Adjust the height as needed */
  background-color: lightgray; /* Adjust color as needed */
  margin-top: 10px; /* Space between text and border */
}
</style>
