<!-- src/views/Creators.vue -->
<template>
  <div>
    <welcome-text />
    <direction />
    <section ref="creatorsSection" class="creators-section">
      <peoplecard
        v-for="creator in creators"
        :key="creator.id"
        :creator="creator"
      />
    </section>
  </div>
</template>

<script>
import { creators } from "../data/creators";
import Peoplecard from "../components/Peoplecard.vue";
import WelcomeText from "../components/Welcome-Text.vue";
import Direction from "../components/Direction.vue";

export default {
  components: { Peoplecard, WelcomeText, Direction },
  data() {
    return {
      creators,
    };
  },
};
</script>

<style scoped>
.creators-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 60px;
}

@media (max-width: 1200px) {
  .creators-section {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 60px;
  }
}

@media (max-width: 992px) {
  .creators-section {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .creators-section {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
  }
}

@media (max-width: 480px) {
  .creators-section {
    grid-template-columns: 1fr;
    margin-top: 60px;
  }
}
</style>
