<template>
  <div class="faq-content">
    <div class="two-section-faq-container">
      <h1 class="title">
        <span class="Faq-name">FAQ</span>
      </h1>
      <two-section-faq />
    </div>
  </div>
</template>

<script>
import TwoSectionFaq from "../components/TwoSectionFaq.vue";
export default {
  components: {
    TwoSectionFaq,
  },
};
</script>

<style scoped>
.Faq-name {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  font-weight: 900;
  font-size: 45px;
}

.title {
  text-align: center;
  margin-bottom: 65px;
}

.faq-content {
  margin-bottom: 115px;
}
</style>
