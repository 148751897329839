import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home.vue";
import About from "../pages/About.vue";
import Cheat from "../pages/Cheat.vue";
import CheatId from "../pages/CheatId.vue";
import NotFoundPage from "../pages/NotFound.vue";
import FAQ from "../pages/FAQ.vue";
import Creators from "../pages/Creators.vue";
import Peoplecard from "../components/Peoplecard.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/cheat",
    name: "cheat-not-found",
    component: NotFoundPage,
  },
  {
    path: "/cheat/:id",
    name: "cheat",
    component: Cheat,
  },
  {
    path: "/cheat/:id/:cheatid",
    name: "cheat-id",
    component: CheatId,
  },

  {
    path: "/FAQ",
    name: "FAQ",
    component: FAQ,
  },
  { path: "/Creators", name: "Creators", component: Creators },
  { path: "/Creators", name: "Creators-id", component: Peoplecard },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top
    return { left: 0, top: 0 };
  },
});

export default router;
