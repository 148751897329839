export const publicDirectLink =
  "https://coordinatediningroom.com/gkpz6avn?key=f85f54b0a8171a5461baff2920f4aa64";

export const stfuradopted = {
  id: "1",
  name: "Stfuradopted",
  description: "GTA V Mods & More",
  src: require("@/assets/stfuradopted.png"),
  url: "https://www.youtube.com/@stfuradopted1/featured",
  directLink:
    "https://coordinatediningroom.com/mngn63y9c?key=2b1895354ad7840ce552a14332085938",
};

export const garth = {
  id: "2",
  name: "Garth",
  description: "GTA V Mods",
  src: require("@/assets/garth.png"),
  url: "https://www.youtube.com/@garthhhh/videos",
  directLink:
    "https://coordinatediningroom.com/rsz1u3e24s?key=f25047e97d46da3bb84216de2eebb85c",
};

export const creators = [
  stfuradopted,
  garth,
  {
    id: "3",
    name: "Creator 3",
    description: "coming soon.",
    src: require("@/assets/blank.png"),
    directLink: "",
  },
  {
    id: "4",
    name: "Creator 4",
    description: "coming soon.",
    src: require("@/assets/blank.png"),
    directLink: "",
  },
  {
    id: "5",
    name: "Creator 5",
    description: "coming soon.",
    src: require("@/assets/blank.png"),
    directLink: "",
  },
  {
    id: "6",
    name: "Creator 6",
    description: "coming soon.",
    src: require("@/assets/blank.png"),
    directLink: "",
  },
];
