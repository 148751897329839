<template>
  <router-link
    style="text-decoration: none"
    :to="'/cheat/' + videogameId + '/' + cheat.id"
  >
    <div class="cheats">
      <div class="cheats-inner">
        <div class="cheats-image-wrap">
          <img
            :src="cheat.cheatcard"
            class="image"
            draggable="false"
            alt="Cheating Sofware images for all types of cheats like fortnite, Rainbow Six Siege, Roblox and more!"
          />
        </div>
        <div class="cheats-details">
          <h2>{{ cheat.cheattitle }}</h2>
          <p>{{ cheat.description }}</p>
          <div class="status">
            <span
              :style="{
                backgroundColor:
                  cheat.status === 'Detected'
                    ? 'red'
                    : cheat.status === 'Sponsor'
                    ? 'blue'
                    : cheat.status === 'Outdated'
                    ? '#FF8C00'
                    : 'green',

                color: 'white',
                padding: '5px',
                borderRadius: '15px',
                textAlign: 'center',
              }"
            >
              {{ cheat.status }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Cheatcard",
  props: {
    cheat: {
      type: Object,
      required: true,
    },
    videogameId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.cheats {
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.cheats-inner {
  position: relative;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  height: 200px;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cheats-inner:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.status {
  position: absolute;
  padding-right: 5px;
  padding-top: 5px;
  right: 0;
  top: 0;
}

.cheats-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  overflow: hidden;
}

.cheats-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

.cheats-image-wrap img:hover {
  transform: scale(1.05);
}

.cheats-details {
  text-align: center;
  margin-bottom: 5px;
  padding: 10px;
  flex-grow: 1;
}

.cheats-details h2 {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  margin: 8px 0;
  line-height: 1.4;
  text-transform: none;
  letter-spacing: 0.2px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.cheats-details p {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 1200px) {
  .cheats-inner {
    max-width: 360px;
    height: 190px;
  }
}

@media (max-width: 992px) {
  .cheats-inner {
    max-width: 340px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .cheats-inner {
    max-width: 320px;
    height: 220px;
  }

  .cheats-image-wrap {
    height: 60%;
  }
}

@media (max-width: 480px) {
  .cheats-inner {
    max-width: 300px;
    height: 200px;
  }

  .cheats-image-wrap {
    height: 60%;
  }
}
</style>
