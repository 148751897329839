<template>
  <div>
    <welcome-text @scrollToGames="scrollToGames" />
    <direction />
    <section ref="gamesSection" class="games-section">
      <games v-for="game in videogames" :key="game.title" :game="game" />
    </section>
  </div>
</template>

<script>
import WelcomeText from "../components/Welcome-Text.vue";
import Games from "../components/Gamecard.vue";
import data from "../data/videogame";
import Direction from "../components/Direction.vue";

export default {
  components: { WelcomeText, Games, Direction },
  data() {
    return {
      videogames: data.videogames,
    };
  },
  methods: {
    scrollToGames() {
      const target = this.$refs.gamesSection;
      this.smoothScroll(target, 1000); // Adjust duration for slower scroll
    },
    smoothScroll(target, duration) {
      const start = window.pageYOffset;
      const end = target.getBoundingClientRect().top + start;
      const distance = end - start;
      let startTime = null;

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, start, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    },
  },
};
</script>

<style scoped>
.games-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 45px;
}

@media (max-width: 1200px) {
  .games-section {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 45px;
  }
}

@media (max-width: 992px) {
  .games-section {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 45px;
  }
}

@media (max-width: 768px) {
  .games-section {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 45px;
  }
}

@media (max-width: 480px) {
  .games-section {
    grid-template-columns: 1fr;
    margin-top: 45px;
  }
}
</style>
