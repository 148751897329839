import YoutubeEmbed from "../components/YoutubeEmbed.vue";
import { garth, stfuradopted, publicDirectLink } from "./creators";

// src/data/cheatdata.js
export default {
  cheats: [
    {
      id: 1, //GRAND THEFT AUTO V
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/kiddions.png"),
          cheattitle: "Kiddion's External Menu",
          description:
            "The Kiddions Mod Menu enhances GTA 5 gameplay with features...",
          status: "Undetected",
          features:
            "The Kiddions Mod Menu enhances GTA 5 gameplay with features like stealth money drops, vehicle spawning, and casino manipulation, while being user-friendly and low-risk. It offers protection from other modders and is easy to navigate using keyboard shortcuts. Disabling antivirus before installation is recommended for a smooth setup.",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/husVHLzK#15MdbyLT_IfwGngyA9Wohw",
          directLink: garth.directLink,
          youtubeId: "AX2NYFGB3lY",
        },
        {
          id: 2,
          cheatcard: require("@/assets/moneytool.jpeg"),
          cheattitle: "Money Tool",
          description: "The Money Tool mod menu for GTA 5 Online...",
          status: "Undetected",
          features:
            "The Money Tool mod menu for GTA 5 Online is a powerful tool designed for players seeking to enhance their in-game wealth effortlessly. This mod provides features to generate large sums of in-game currency, allowing users to bypass the grind and acquire high-value assets quickly. It's popular among those looking to enjoy a luxurious virtual lifestyle without the usual effort. ",
          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/N7FhjIBR#5Tn82xTnxz7M4Zku2WzTVg",
          directLink: publicDirectLink,
          // directLink: stfuradopted.directLink
        },
        {
          id: 3,
          cheatcard: require("@/assets/YimMenu.png"),
          cheattitle: "Yim-Menu",
          description: "YimMenu is a hobby project developed by a community...",
          status: "Undetected",
          features:
            "YimMenu is a hobby project developed by a community of independent developers during their free time. This GTA 5 mod menu offers a wide array of features designed to enhance and customize gameplay. As a community-driven project, it continuously evolves based on user feedback and contributions, reflecting the collaborative spirit and dedication of its creators to improving the gaming experience.",
          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/xj1HgYTD#_1upR1zq8xIUFrHKMID_7w",
          directLink: garth.directLink,
        },
        {
          id: 4,
          cheatcard: require("@/assets/N3NY000.jpg"),

          cheattitle: "Nenyooo-Menu",
          description:
            "Enhance your GTA 5 Online experience with the N3NY000...",
          status: "Undetected",
          features:
            "Enhance your GTA 5 Online experience with the N3NY000 cheat. This compact menu offers powerful features like Godmode, Auto Heal, Teleport, full Speed Hack, Invisible mode, Hide Radar, No Clip, and advanced Weapon and Vehicle options. Enjoy additional enhancements such as speed adjustments for running, swimming, and weapon reloading, alongside various upgradeable functions. Given its robust capabilities, the N3NY000 cheat is highly recommended for any GTA 5 Online player looking to improve their gameplay conditions.",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/5j0hGRoY#Srj297SW2hHv3rg2YybuIw",
          directLink: publicDirectLink,
        },
        {
          id: 5,
          cheatcard: require("@/assets/LSCHaX.png"),

          cheattitle: "LSCHaX-Menu",
          description: "LSCHaX is a versatile cheat tool designed for GTA...",
          status: "Outdated",
          features:
            "LSCHaX is a versatile cheat tool designed for GTA 5, offering a simple car editor within the modshop. It allows users to modify vehicles easily, including transforming standard cars into Elegy Customs, which can be a lucrative moneymaking method. Additionally, it provides the option to customize cars uniquely, such as adding unusual features like a BeeJay XL with a bodybag on the seats, catering to players seeking distinct and personal touches.",
          star: require("@/assets/star2.png"),
          downloadLink:
            "https://mega.nz/folder/07dzgZzI#Eh5TrfM9CNFo_KCdXb69NQ",
          directLink: publicDirectLink,
        },
        {
          id: 6,
          cheatcard: require("@/assets/immunity.png"),

          cheattitle: "Immunity-Menu",
          description: "Immunity-Menu is a powerful GTA 5 mod menu...",
          status: "Outdated",
          features:
            "Immunity-Menu is a powerful GTA 5 mod menu tailored for enhancing gameplay in GTA Online. Created by a dedicated group of developers, it offers a range of features including advanced cheats, customization options, and gameplay enhancements. Designed to be user-friendly, it allows players to modify various aspects of the game, providing a more personalized and enriched gaming experience while ensuring smooth operation.",

          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/g7FiQJSS#pjRFYKjuDxV6zeRxWJnArg",
          directLink: publicDirectLink,
        },
        {
          id: 7,
          cheatcard: require("@/assets/XDEVPS.png"),

          cheattitle: "XDev Outfit Editor",
          description: "The XDEV Outfit Editor is a tool used in...",
          status: "Undetected",
          features:
            "The XDEV Outfit Editor is a tool used in GTA Online that allows players to modify and customize their character's outfits beyond the standard options available in the game. This tool enables users to mix and match clothing items, accessories, and other gear in ways that might not be possible through the game's regular outfit customization system.",

          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/luMXFAqQ#Ta2O_89hNNgSXIvRNvOQTg",
          directLink: garth.directLink,
          youtubeId: "iMGdO1B_TIQ",
        },
      ],
    },
    {
      id: 2, //Tom Clancy's Rainbow Six Siege
      cheats: [
        {
          id: 1,
          cheatcard: "https://i.imgur.com/Rzw0sH6.gif",
          cheatcard2: require("@/assets/lua.png"),
          cheatcard3: require("@/assets/luasave.png"),
          cheattitle: "Logitech Anti-Recoil Script",
          description:
            "The Logitech Anti-Recoil Script is a custom script designed...",
          status: "Undetected",
          features:
            "The Logitech Anti-Recoil Script is a custom script designed to mitigate weapon recoil in various video games, improving accuracy and overall performance. This script utilizes Logitech gaming hardware to automate recoil compensation, providing a more stable shooting experience. It can be adjusted to fit different weapons and playstyles, helping players maintain precision and control during intense gameplay scenarios. The script offers customizable settings to fine-tune recoil adjustment based on personal preferences and specific game mechanics.",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/x6kQBIBL#PVKzt3N0t-nB6YzCpiOhIQ",
          directLink: publicDirectLink,
        },
        {
          id: 2,
          cheatcard: require("@/assets/Scripzz2.png"),
          cheatcard2: require("@/assets/Scripzz.png"),
          cheatcard3: require("@/assets/Scripzz3.png"),
          cheattitle: "Scripzz v1.4",
          description:
            "Scripzz, a recoil script for Rainbow Six, features a...",
          status: "Undetected",
          features:
            "Scripzz, a recoil script for Rainbow Six, features a value changer and an attractive GUI. In its v1.4 version, it includes presets for various operators in the game, allowing for quick and efficient adjustments. The user-friendly interface ensures seamless customization, enhancing gameplay by minimizing recoil effortlessly. This tool is essential for players seeking a competitive edge in Rainbow Six.",
          star: require("@/assets/star4.png"),
          directLink: publicDirectLink,
        },
        {
          id: 3,
          cheatcard: require("@/assets/noclip.png"),
          cheattitle: "No Clip",
          description:
            "The free R6 cheat (DLL) offers various features such...",
          status: "Use at your own risk",
          features:
            "The free R6 cheat (DLL) offers various features such as added Chams, bullets per shot, speed changer, and no recoil, along with an updated no clip. Additionally, it includes an aimbot for precise targeting, wallhack to see through walls, and an ESP (Extra Sensory Perception) feature that displays player information. The cheat also provides customizable settings to tailor the experience to individual preferences, ensuring an enhanced gaming experience.",
          star: require("@/assets/star2.png"),
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 5,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/galaxyswapperui.png"),
          cheattitle: "Galaxy Swapper v2",
          description: "Galaxy Swapper v2 is a free Fortnite skin changer...",
          status: "Undetected",
          features:
            "Galaxy Swapper v2 is a free Fortnite skin changer developed using WPF technology. It allows you to effortlessly modify your game files to replace a cosmetic you own with one you desire. The interface is designed to be simple and user-friendly, ensuring a straightforward and convenient experience for all your cosmetic customization needs.",
          star: require("@/assets/star4.png"),
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 6,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/orbit.png"),
          cheattitle: "Orbit",
          description:
            "Orbit enhances your gameplay with key features: an aimbot... ",
          status: "Undetected",

          features:
            "Orbit enhances your gameplay with key features: an aimbot with customizable aim and recoil, visible target checks, and adjustable FOV. It offers visual upgrades like player names, weapon icons, and healthbars. Additional features include bomb and player info, movement tracking, and a spectator list. Orbit also hides from recordings, includes tooltips, and supports auto-accept, full-screen mode, various themes, and Lua scripting. Upgrade your game with Orbit’s powerful, customizable tools.",
          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/UyMEQRLb#1DK17A742GO37yLQu9oZ7w",
          directLink: publicDirectLink,
        },
        {
          id: 2,
          cheatcard: require("@/assets/Aimipic.png"),

          cheattitle: "AIMi",
          description:
            "AIMi is an advanced AI aimbot designed for Counter-Strike...",
          status: "Undetected",
          features:
            "AIMi is an advanced AI aimbot designed for Counter-Strike: Global Offensive (CSGO) that utilizes real-time object detection. By analyzing and identifying targets instantly during gameplay, AIMi enhances accuracy and aiming precision. Its sophisticated algorithms adapt to various game scenarios, ensuring players gain a competitive edge with improved targeting and reaction times without manual adjustments.",
          star: require("@/assets/star2.png"),
          downloadLink:
            "https://mega.nz/folder/pmkCxYwR#vcgBOdjJojzBzmCBvgsFsg",
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 8,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/r3th-priv.png"),

          cheattitle: "R3TH PRIV",
          description:
            "Script for 'Murder Mystery 2,' 'FOBLOX,' and 'Total Roblox Drama' ",
          status: "Undetected",
          features: "",
          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/8vdhEJZK#IX5-KRzmVFalRDl82Ri2pA",
          directLink: publicDirectLink,
        },
        {
          id: 2,
          cheatcard: require("@/assets/vortex-roblox.jpg"),

          cheattitle: " DA hood Vortex Script GUI",
          description:
            "A strong, safe, and reliable script for 'DA Hood' on Roblox.",
          status: "Undetected",
          features: "",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/Q2sykYpS#hsO5Ff69jHjT5Jiwmw9nSw",
          directLink: publicDirectLink,
        },
        {
          id: 3,
          cheatcard: require("@/assets/polakya.jpg"),

          cheattitle: "Da Hood POLAKYA Script GUI",
          description: "God Mode + Aimbot | Backflip Bot",
          status: "Undetected",
          features: "",
          star: require("@/assets/star3.png"),
          downloadLink:
            "https://mega.nz/folder/gvckQSiB#NBC9dKyrkdNulU1Vu4iifA",
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 9,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/MC.png"),
          cheatcard2: require("@/assets/qbitadobe.png"),
          cheatcard3: require("@/assets/adobefinal.png"),
          cheattitle: "Adobe 2023 Master Collection",
          description: "Master Collection of All 2023 Adobe Products.",
          status: "Approved",
          features:
            "Unlock creative potential with the Adobe Master Collection torrent, offering a suite of powerful tools: Photoshop, Illustrator, Premiere Pro, After Effects, and more. Streamline your workflow with advanced photo editing, vector graphics, video production, and special effects. Enjoy features like customizable brushes, color grading, motion graphics, and 3D design. Ideal for professionals and enthusiasts, the Adobe Master Collection torrent enhances productivity and creativity across all media projects.",
          star: require("@/assets/star5.png"),
          downloadLink:
            "https://mega.nz/folder/putHDbzK#H0X4h6xuqLM24FQyUrDRJA",
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 10,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/spotdl.png"),
          cheatcard2: require("@/assets/ADD_TO_PATH.png"),
          cheattitle: "SpotDL",
          description: "SpotDL uses YouTube as a source for music downloads...",
          status: "Approved",
          features:
            "SpotDL uses YouTube as a source for music downloads due to its vast and diverse library. This method is favored to avoid any potential issues related to downloading music directly from Spotify, ensuring users have a reliable way to access and save their favorite tracks without running into legal or technical problems often associated with other methods.",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/cuUmjRzZ#EZ7tYN2_C_cBlHmuMyjnjQ",
          directLink: publicDirectLink,
        },
        {
          id: 2,
          cheatcard: require("@/assets/spotx.png"),
          cheattitle: "SpotX",
          description: "SpotX blocks all banner, video, and audio ads in...",
          status: "Approved",
          features:
            "SpotX blocks all banner, video, and audio ads in the client, effectively creating a more streamlined and uninterrupted user experience. Additionally, it offers options for hiding podcasts, episodes, and audiobooks from the homepage, blocking Spotify automatic updates, and enabling more experimental features. It also disables Sentry's console log/error/warning messages to Spotify developers, halts user interaction logging, and eliminates right-to-left CSS rules for simplification, along with performing code minification.",
          star: require("@/assets/star5.png"),
          downloadLink:
            "https://mega.nz/folder/hjtgyQDS#7r_AeLpezdzrDgU5zA_pNQ",
          directLink: publicDirectLink,
        },
      ],
    },
    {
      id: 11,
      cheats: [
        {
          id: 1,
          cheatcard: require("@/assets/exteremeinjector.png"),
          cheattitle: "Extreme Injector",
          description:
            "The Extreme Injector features a vibrant, customizable GUI and...",
          status: "Undetected",
          features:
            "The Extreme Injector features a vibrant, customizable GUI and supports advanced multi-DLL injection with options for auto-inject, stealth mode, and DLL scrambling. It offers various injection methods, including Standard and Manual Map, and supports 64-bit systems. Users can drag and drop DLLs, manage dependencies with an automatic Visual C++ installer, and execute functions post-injection.",
          star: require("@/assets/star5.png"),
          downloadLink:
            "https://mega.nz/folder/t6UU0JLC#jxXgwCm-A4Cg-CI7MkaZHA",
          directLink: publicDirectLink,
        },
        {
          id: 2,
          cheatcard: require("@/assets/xenos.png"),
          cheattitle: "Xenos Injector",
          description:
            "Xenos Injector is a comprehensive tool for injecting code...",
          status: "Undetected",
          features:
            "Xenos Injector is a comprehensive tool for injecting code into both x86 and x64 processes and modules. It supports kernel-mode injection (requiring a driver) and manual mapping of kernel drivers. The tool allows for the injection of pure managed images without needing a proxy DLL, supports Windows 7 cross-session and cross-desktop injection, and can inject into native processes with only ntdll loaded.",
          star: require("@/assets/star4.png"),
          downloadLink:
            "https://mega.nz/folder/Mn1B2YRb#tWbig9RNbmMd1bYVT8JxIA",
          directLink: publicDirectLink,
        },
      ],
    },
  ],
};
