<template>
  <div class="directions-container">
    <ul class="navigation">
      <li>
        <router-link class="link" :to="{ name: 'home' }">
          Collection<svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="icon-svg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z"
            />
          </svg>
        </router-link>
      </li>

      <div class="divider"></div>

      <li>
        <router-link class="link" :to="{ name: 'Creators' }"
          >Affiliates
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="icon-svg"
            viewBox="0 0 16 16"
          >
            <path
              d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
            />
          </svg>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="line-container">
    <div class="line"></div>
  </div>
</template>

<style scoped>
.line {
  margin-top: 15px;
  width: 145px;
  height: 2px;
  background-color: black; /* Adjust color as needed */
  display: flex;
  justify-content: center;
}

.line-container {
  display: flex;
  justify-content: center;
}
.divider {
  width: 2px; /* Set the width to make it vertical */
  height: 20px; /* Adjust the height as needed */
  background-color: black; /* Color of the divider */
  align-self: center; /* Align the divider vertically in the list item */
}
.directions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  width: 100%;
  margin-top: 170px;
}
.navigation {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
}
ul,
.link {
  font-weight: 600;
  color: white;
  list-style: none;
  text-decoration: none;
}

.Li {
  text-transform: uppercase;
  margin-left: 16px;
}

.link {
  transition: 0.6s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  color: black;
}

.link:hover {
  color: black;
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.icon-svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
