<template>
  <div class="youtube-embed-wrapper" v-if="youtubeId">
    <iframe
      class="youtube-embed"
      :src="`https://www.youtube.com/embed/${youtubeId}?modestbranding=1&controls=1&showinfo=0&rel=0`"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    youtubeId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.youtube-embed-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 100%;
  overflow: hidden;
  cursor: pointer; /* Ensures cursor is visible */
}

.youtube-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
