<template>
  <div
    v-if="isVisible"
    class="Fixed-discord-container"
    :class="{ 'pop-out': isPoppedOut }"
  >
    <div class="fixed-discord">
      <button class="join-discord-btn" @click="redirectToDiscord">
        Let's chat on Discord!
      </button>
      <div class="circle-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          class="svg-fixed-discord"
        >
          <path
            d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"
          />
        </svg>
      </div>
      <button
        class="close-btn"
        aria-label="Close-Button"
        @click="closeComponent"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
      isPoppedOut: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isPoppedOut = true;
    }, 1000);
  },
  methods: {
    closeComponent() {
      this.isVisible = false;
    },
    redirectToDiscord() {
      window.open("https://discord.gg/fQsrHqjNfy", "_blank");
    },
  },
};
</script>

<style scoped>
.Fixed-discord-container {
  position: fixed;
  bottom: 0;
  right: -300px;
  margin: 20px;
  transition: right 0.5s ease-in-out;
}

.fixed-discord {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.join-discord-btn {
  padding: 10px;
  background-color: black;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: linear-gradient(90deg, #a800ff, #ff5e8e);
  border-radius: 50%;
}

.svg-fixed-discord {
  color: white;
}

.close-btn {
  position: absolute;
  bottom: 5px;
  left: -20px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.pop-out {
  right: 0;
}
</style>
