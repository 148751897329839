export function openNewTab(url) {
    window.open(url, '_blank');
    return true;
}

export function openAdDirectLink() {
    const directLinkUrl = process.env.VUE_APP_AD_STERA_DIRECT_LINK
    return openNewTab(directLinkUrl)
}



