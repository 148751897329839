<template>
  <div class="creator-card" @click="redirectToUrl">
    <div class="creator-card-inner">
      <div class="creator-image-wrap">
        <img
          :src="creator.src"
          class="image"
          draggable="false"
          alt="Creator Image"
        />
      </div>
      <div class="creator-details">
        <h2>{{ creator.name }}</h2>
        <p>{{ creator.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreatorCard",
  props: {
    creator: {
      type: Object,
      required: true,
    },
  },
  methods: {
    redirectToUrl() {
      if (this.creator.url) {
        window.open(this.creator.url, "_blank", "noopener,noreferrer");
      }
    },
  },
};
</script>

<style scoped>
.creator-card {
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  cursor: pointer; /* Show that the card is clickable */
}

.creator-card-inner {
  position: relative;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  height: 200px;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.creator-card-inner:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.creator-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  overflow: hidden;
}

.creator-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

.creator-image-wrap img:hover {
  transform: scale(1.05);
}

.creator-details {
  text-align: center;
  margin-bottom: 5px;
  padding: 10px;
  flex-grow: 1;
}

.creator-details h2 {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  margin: 8px 0;
  line-height: 1.4;
  text-transform: none;
  letter-spacing: 0.2px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.creator-details p {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 1200px) {
  .creator-card-inner {
    max-width: 360px;
    height: 190px;
  }
}

@media (max-width: 992px) {
  .creator-card-inner {
    max-width: 340px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .creator-card-inner {
    max-width: 320px;
    height: 220px;
  }

  .creator-image-wrap {
    height: 60%;
  }
}

@media (max-width: 480px) {
  .creator-card-inner {
    max-width: 300px;
    height: 200px;
  }

  .creator-image-wrap {
    height: 60%;
  }
}
</style>
