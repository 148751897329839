<template>
  <div class="not-found">
    <h1 class="not-found-text">404</h1>
    <p class="not-found-description">Page Not Found.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 20vh; /* Adjust this value to move the text up or down */
  text-align: center;
}

.not-found-text {
  font-size: 10rem;
  font-weight: 900;
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  margin: 0;
}

.not-found-description {
  font-size: 1.5rem;
  color: #333;
  margin-top: 1rem;
  font-weight: 600;
}
</style>
