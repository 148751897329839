<template>
  <div class="about-content">
    <div class="space-inner"></div>
    <section class="about-section">
      <div class="about-text">
        <h1 class="title">
          About <span class="brand-name-about">UDhacks</span>
        </h1>
        <p class="about-p">
          At UDhacks, we offer a comprehensive range of free, secure video game
          cheats and torrents for numerous popular titles. Our mission is to
          enhance your gaming experience and help you stay ahead in the gaming
          world with ease.
        </p>
        <button class="search-button" @click="scrollToSection2">
          Learn More
        </button>
      </div>
      <img class="gtavimg" src="@/assets/gtavpop.png" draggable="false" />
    </section>
    <section id="section2" class="about-section section2-layout">
      <img
        class="section2-img"
        src="@/assets/darkbomber.png"
        draggable="false"
      />
      <div class="about-text">
        <h1 class="title">
          Download <span class="brand-name-about">Now</span>
        </h1>
        <p class="about-p">
          Getting your hands on free cheats has never been easier. Choose the
          cheats you want from the available options and follow the
          straightforward instructions to download and install them. It’s that
          simple! Enjoy enhanced gameplay with just a few clicks. Explore and
          unlock exciting new possibilities today!
        </p>
        <button class="search-button" @click="scrollToSection3">
          Learn More
        </button>
      </div>
    </section>
    <section id="section3" class="about-section section3-layout">
      <img class="section3-img" src="@/assets/finka.png" draggable="false" />
      <div class="about-text">
        <h1 class="title">
          Support<span class="brand-name-about"> 24/7</span>
        </h1>
        <p class="about-p">
          If you need assistance with any of our cheats or have questions about
          their use, feel free to join our Discord community. We’re available
          24/7 to help you with any issues or concerns you might have. Our
          dedicated team is always on hand to provide support and ensure you
          have the best experience possible.
        </p>
        <button class="search-button" @click="openDiscord">Discord</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToSection2() {
      const element = document.getElementById("section2");
      if (element) {
        this.smoothScroll(element, 1000);
      }
    },
    scrollToSection1() {
      const element = document.querySelector(".about-section");
      if (element) {
        this.smoothScroll(element, 1000);
      }
    },
    scrollToSection3() {
      const element = document.getElementById("section3");
      if (element) {
        this.smoothScroll(element, 1000);
      }
    },
    openDiscord() {
      window.open("https://discord.gg/fQsrHqjNfy", "_blank");
    },
    smoothScroll(target, duration = 1000) {
      const start = window.scrollY || window.pageYOffset;
      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset;
      const distance = targetPosition - start;
      let startTime = null;

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, start, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    },
  },
};
</script>
<style>
.about-content {
  margin-top: 40px;
}

.space-inner {
  margin-top: 80px;
}

.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 80px;
}

.about-text {
  text-align: center;
  flex: 1;
}

.about-p {
  text-align: center;
  margin: 10px auto;
  max-width: 680px;
  font-size: 20px;
  line-height: 1.6;
  color: black;
  font-weight: 500;
  padding: 10px;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #a800ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 35px;
}

.title {
  font-size: 3.5rem;
  line-height: 1.2em;
  font-weight: 900;
  color: #000;
  text-align: center;
  margin: 20px auto 10px;
}

.brand-name-about {
  font-size: 3.5rem;
  line-height: 1.2em;
  font-weight: 900;
  color: #000;
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
}

.gtavimg {
  width: 44%;
  height: auto;
  border-radius: 50px;
  opacity: 0;
  transform: scale(0.8);
  animation: popOut 1s forwards;
  transition: transform 0.3s ease;
}

@keyframes popOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1250px) {
  .about-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    font-size: 2.5rem;
  }

  .about-p {
    font-size: 16px;
    max-width: 600px;
  }

  .gtavimg {
    width: 50%;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about-section {
    flex-direction: column-reverse;
    align-items: center;
  }

  .title {
    font-size: 2.2rem;
    margin-top: 10px;
  }

  .brand-name-about {
    font-size: 2.2rem;
  }

  .about-p {
    font-size: 15px;
    margin-bottom: 15px;
    max-width: 90%;
  }

  .gtavimg {
    width: 80%;
    border-radius: 80px;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 180px;
  }

  .title {
    font-size: 2rem;
    margin-top: 10px;
  }

  .brand-name-about {
    font-size: 2rem;
  }

  .about-p {
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 100%;
  }

  .gtavimg {
    width: 100%;
    border-radius: 80px;
    margin-bottom: 0;
  }
}

.section2-layout {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section2-img {
  width: 35%;
  margin: 0;
  margin-bottom: 30px;
}

.section3-layout {
  margin-top: 100px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.section3-img {
  width: 35%;
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .section2-layout,
  .section3-layout {
    flex-direction: column;
    align-items: center;
  }

  .section2-img,
  .section3-img {
    width: 100%;
    border-radius: 80px;
    margin-bottom: 20px;
  }

  .about-text {
    text-align: center;
    max-width: 90%;
  }
}
</style>
