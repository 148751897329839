<template>
  <div class="welcome-content">
    <h1 class="title">
      Welcome to <span class="brand-name">UDhacks.com</span>
    </h1>
    <p class="description">
      Discover a vast collection of free, safe, and
      <span class="bolded"> undetected</span> video game cheats and torrents for
      a wide range of popular titles. Enhance your gaming experience with ease
      and stay ahead of the competition.
    </p>
    <button class="search-button" @click="scrollToGames">
      View Collection
    </button>
  </div>
</template>

<script>
export default {
  name: "WelcomeText",
  methods: {
    scrollToGames() {
      this.$emit("scrollToGames");
    },
  },
};
</script>

<style scoped>
.welcome-content {
  text-align: center;
  margin-top: 50px;
}

.title {
  font-size: 45px;
  font-weight: 800;
}

.brand-name {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  font-weight: 900;
  font-size: 45px;
}

.description {
  font-size: 18px;
  margin-top: 10px;
  line-height: 1.6;
  color: #555;
  max-width: 680px;
  margin: 20px auto;
  font-weight: 500;
  padding: 10px;
}

.bolded {
  font-weight: 900;
  color: black;
}

.search-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #a800ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
