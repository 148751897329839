<template>
  <div class="app-container">
    <Navigation />
    <div class="main-content">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
    <Footer />
    <fixed-discord />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import FixedDiscord from "./components/FixedDiscord.vue";

export default {
  components: {
    Navigation,
    Footer,
    FixedDiscord,
  },
};
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  padding-top: 100px;
}
</style>
