export default {
  videogames: [
    {
      id: 1,
      title: "Grand Theft Auto V",
      description: "Mod Menues | cash/rp.",
      src: "gtav",

      src: require("@/assets/GTAV.jpg"),
      pagetext:
        "Rule Los Santos with the latest and most effective Grand Theft Auto V hacks and cheats today!",
    },
    {
      id: 2,
      title: "Tom Clancy's Rainbow Six Siege",
      description: "Esp, Aimbot, Recoil Scripts.",
      src: "r6",

      src: require("@/assets/r6.jpg"),
      pagetext:
        "Secure victory with Rainbow Six Siege hacks and cheats! Arm yourself with the latest and most effective hacks and cheats tailored for Rainbow Six Siege's tactical gameplay today! ",
    },
    {
      id: 3,
      title: "Valorant",
      description: "Coming Soon.",
      src: "valorant",
      cheatProviders: [],
      src: require("@/assets/valorant.jpg"),
      pagetext:
        "levate your tactical gameplay with Valorant hacks and cheats! Equip yourself with the latest tools to dominate the tactical arenas of Riot Games' competitive shooter, Valorant. ",
    },
    {
      id: 4,
      title: "Rust",
      description: "Coming Soon.",
      src: "rust",
      cheatProviders: [],
      src: require("@/assets/rust.jpg"),
    },
    {
      id: 5,
      title: "Fortnite",
      description: "Esp, Aimbot | Recoil Scripts.",
      src: "fortnite",
      cheatProviders: [],
      src: require("@/assets/fortnite.jpg"),
      pagetext:
        "Take control of your battlefield with our undetected cheats. Our Fortnite hacks will help you rise to the top effortlessly.",
    },
    {
      id: 6,
      title: "Counter-Strike 2",
      description: "Esp, Aimbot | Recoil Scripts.",
      src: "csgo2",
      cheatProviders: [],
      src: require("@/assets/csgo2.jpg"),
      pagetext:
        "Counter-Strike 2 hacks and cheats empower you with precision aimbots, wallhacks for strategic advantage, ESP for situational awareness, and customizable mods.",
    },
    {
      id: 7,
      title: "Apex Legends",
      description: "Coming Soon.",
      src: "apexlegends",
      cheatProviders: [],
      src: require("@/assets/apexlegends.jpg"),
    },
    {
      id: 8,
      title: "Roblox",
      description: "Executors, Scripts, No-clip | Aimbot.",
      src: "roblox",
      cheatProviders: [],
      src: require("@/assets/roblox.jpg"),
      pagetext:
        "Secure victory with Roblox hacks and cheats! Equip yourself with the latest and most effective hacks and cheats tailored for Roblox's diverse gameplay today!",
    },

    {
      id: 9,
      title: "Adobe Products",
      description: "Adobe Applications.",
      src: "adobe",
      cheatProviders: [],
      src: require("@/assets/adobe.jpg"),
    },
    {
      id: 10,
      title: "Spotify Patches",
      description: "Spotify ++ patch | Spotify HD file downloader. ",
      src: "spotify",
      cheatProviders: [],
      src: require("@/assets/spotify.jpg"),
    },
    {
      id: 11,
      title: "Misc",
      description: "variety of topics.",
      src: "MISC",
      cheatProviders: [],
      src: require("@/assets/MISC.png"),
      pagetext:
        "Explore a variety of topics and content that don't fit into our main categories.",
    },
    {
      id: 12,
      title: "Minecraft",
      description: "Coming Soon.",
      src: "Minecraft",
      cheatProviders: [],
      src: require("@/assets/Minecraft.jpg"),
      pagetext: "",
    },
  ],
};
