<template>
  <div class="cheatid" v-if="videogame && cheat">
    <div class="title"></div>
    <div class="cheatid-content">
      <div class="h1-cheatid"></div>
      <div class="carousel-wrapper">
        <div class="carousel" v-if="hasContent">
          <button
            class="carousel-button prev"
            aria-label="prev"
            @click="prevSlide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="chevron-icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <div class="carousel-slide">
            <div
              class="cheatid-img"
              v-for="(content, index) in cheatContent"
              :key="index"
              v-show="currentSlide === index"
            >
              <YoutubeEmbed
                v-if="content.type === 'youtube'"
                :youtubeId="content.id"
              />
              <img
                v-if="content.type === 'cheatcard'"
                :src="content.id"
                :alt="cheat.description"
              />
            </div>
          </div>
          <button
            class="carousel-button next"
            aria-label="next"
            @click="nextSlide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="chevron-icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          <div class="carousel-dots">
            <span
              v-for="(content, index) in cheatContent"
              :key="'dot-' + index"
              class="carousel-dot"
              :class="{ active: currentSlide === index }"
            ></span>
          </div>
        </div>

        <div class="cheatid-content" v-else></div>

        <button class="search-button" alt="download" @click="navigateToLink">
          <svg
            class="dl-btn"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"
            />
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"
            />
          </svg>
          Download - {{ cheat.cheattitle }}
        </button>
      </div>
    </div>
    <div class="features">
      <h1 class="cheat-features-title">
        {{ cheat.cheattitle }} <span class="word-features"> Features </span>
      </h1>
      <h2 class="Features-description">
        {{ cheat.features }}
        <div class="star-image" v-if="cheat.star">
          <img :src="cheat.star" alt="Star" draggable="false" />
        </div>
        <div class="border-line"></div>
      </h2>
    </div>

    <h1 class="syl">
      Since You like <span class="gradient-text">{{ cheat.cheattitle }}</span>
    </h1>
    <div class="cheatcard-list">
      <Cheatcard
        v-for="cheatItem in filteredCheats"
        :key="cheatItem.id"
        :cheat="cheatItem"
        :videogameId="videogame.id"
      />
    </div>
  </div>
</template>

<script>
import CheatData from "../data/cheatdata";
import VideogameData from "../data/videogame";
import YoutubeEmbed from "../components/YoutubeEmbed.vue";
import Cheatcard from "../components/Cheatcard.vue";
import { nextTick } from "vue";
import { openAdDirectLink, openNewTab } from "../util/open-ad-direct-link";

export default {
  components: {
    YoutubeEmbed,
    Cheatcard,
  },
  data() {
    return {
      videogame: null,
      cheat: null,
      currentSlide: 0,
      cheatContent: [],
      minClicks: 4,
      totalClicks: 0,
    };
  },
  beforeRouteUpdate() {
    this.totalClicks = 0;
  },

  computed: {
    hasContent() {
      return this.cheatContent.length > 0;
    },
    filteredCheats() {
      const videogameId = this.videogame ? this.videogame.id : null;
      if (!videogameId) return [];

      const cheats = CheatData.cheats;
      const videogameCheats =
        cheats.find((cheat) => cheat.id === videogameId)?.cheats || [];

      // Exclude the current cheat from the list
      const otherCheats = videogameCheats.filter(
        (cheat) => cheat.id !== this.cheat.id
      );

      // Shuffle the array
      const shuffledCheats = otherCheats.sort(() => 0.5 - Math.random());

      // Return a random selection (e.g., 4 cheats)
      return shuffledCheats.slice(0, 4);
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    $route(to, from) {
      this.loadData();
    },
  },
  methods: {
    navigateToLink() {
      if (this.totalClicks >= this.minClicks) {
        openNewTab(this.cheat.downloadLink);
      } else {
        openNewTab(this.cheat.directLink);
      }
      this.totalClicks++;
    },
    async loadData() {
      const videogameId = Number(this.$route.params.id);
      const cheatId = Number(this.$route.params.cheatid);

      const videogames = VideogameData.videogames;
      const cheats = CheatData.cheats;

      const videogame = videogames.find(
        (videogame) => videogame.id === videogameId
      );

      if (!videogame) {
        this.$router.push("/cheat");
        return;
      }
      this.videogame = videogame;

      const videogameCheats =
        cheats.find((cheat) => cheat.id === videogameId)?.cheats || [];
      const videogameCheat =
        videogameCheats.find((cheat) => cheat.id === cheatId) || null;

      if (!videogameCheat) {
        this.$router.push("/cheat");
        return;
      }
      this.cheat = videogameCheat;

      this.cheatContent = [];
      if (this.cheat.youtubeId) {
        this.cheatContent.push({ type: "youtube", id: this.cheat.youtubeId });
      }
      if (this.cheat.cheatcard) {
        this.cheatContent.push({ type: "cheatcard", id: this.cheat.cheatcard });
      }
      if (this.cheat.cheatcard2) {
        this.cheatContent.push({
          type: "cheatcard",
          id: this.cheat.cheatcard2,
        });
      }
      if (this.cheat.cheatcard3) {
        this.cheatContent.push({
          type: "cheatcard",
          id: this.cheat.cheatcard3,
        });
      }
      if (this.cheat.cheatcard4) {
        this.cheatContent.push({
          type: "cheatcard",
          id: this.cheat.cheatcard4,
        });
      }

      await nextTick(); // Ensure the DOM is updated
    },
    prevSlide() {
      this.currentSlide =
        this.currentSlide === 0
          ? this.cheatContent.length - 1
          : this.currentSlide - 1;
    },
    nextSlide() {
      this.currentSlide =
        this.currentSlide === this.cheatContent.length - 1
          ? 0
          : this.currentSlide + 1;
    },
  },
};
</script>

<style scoped>
.carousel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  height: 480px;
  aspect-ratio: 16/9;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.carousel-slide {
  flex: 1;
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.cheatid-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.cheatid-img img,
.cheatid-img iframe {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  z-index: 10;
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.chevron-icon {
  width: 18px;
  height: 18px;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.carousel-dots {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin: 0;
  z-index: 10;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: gray;
  border-radius: 50%;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.carousel-dot.active {
  background-color: #ff5e8e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 12px 8px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.search-button:hover {
  background: linear-gradient(90deg, #a800ff, #e04e7a);
  transform: scale(1.05);
}

.dl-btn {
  margin-right: 8px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .carousel-wrapper {
    padding-top: 40px;
  }

  .carousel {
    max-width: 90%;
    height: auto;
    aspect-ratio: 16/9;
  }

  .carousel-slide {
    height: 100%;
  }

  .carousel-button {
    padding: 8px;
  }

  .chevron-icon {
    width: 16px;
    height: 16px;
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
  }

  .search-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .carousel-wrapper {
    padding-top: 30px;
  }

  .carousel {
    max-width: 95%;
    height: auto;
  }

  .carousel-slide {
    height: 100%;
  }

  .carousel-button {
    padding: 6px;
  }

  .chevron-icon {
    width: 24px;
    height: 24px;
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
  }

  .search-button {
    font-size: 16px;
    padding: 13px 22px;
  }
}

@media (max-width: 480px) {
  .carousel-wrapper {
    padding-top: 20px;
  }

  .carousel {
    max-width: 100%;
    height: auto;
  }

  .carousel-slide {
    height: 100%;
  }

  .carousel-button {
    padding: 4px;
  }

  .chevron-icon {
    width: 30px;
    height: 30px;
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
  }

  .search-button {
    font-size: 16px;
    padding: 11px 10px;
    height: 40px;
  }
}

.cheat-features-title {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  font-weight: 900;
}

.word-features {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  font-weight: 900;
  margin-left: 10px;
}

.Features-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.85);
  max-width: 800px;
  margin: 20px auto;
  font-weight: 700;
  padding: 5px;
  margin-bottom: 80px;
  text-align: center;
}

.star-image {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  /* Center the image horizontally */
  width: 50px;
  height: 75px;
}

.border-line {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
}

.game-cheat {
  font-weight: 900;
}

.game-title {
  font-size: 35px;
  font-weight: 800;
}

.cheat-title {
  font-weight: 900;
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 480px) {
  .Features-description {
    font-size: 14px;
    padding: 25px;
    font-weight: 700;
    line-height: 2;
  }
}

@media (max-width: 768px) {
  .Features-description {
    font-size: 14px;
    padding: 25px;
    font-weight: 700;
    line-height: 2;
  }
}

@media (max-width: 1024px) {
  .Features-description {
    font-size: 14px;
    padding: 15px;
    font-weight: 700;
  }
}

.syl {
  font-size: 1.6rem;
  text-align: center;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 800;
}

.gradient-text {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  margin-left: 8px;
}

.cheat-features-title {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cheat-features-title .word-features {
  background: linear-gradient(90deg, #ff5e8e, #a800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  font-size: 1.6rem;
}

@media (min-width: 769px) {
  .cheat-features-title {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .cheat-features-title .word-features {
    margin-left: 8px;
    display: inline;
  }

  .syl {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .cheat-features-title {
    flex-direction: column;
  }

  .cheat-features-title .word-features {
    margin-left: 0;
    display: block;
  }

  .syl {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .cheat-features-title {
    font-size: 1.5rem;
  }

  .cheat-features-title .word-features {
    font-size: 1.6rem;
    margin-top: 10px;
  }

  .syl {
    font-size: 1.4rem;
  }
}

.cheatcard-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.cheatcard-list > * {
  flex: 1 1 300px;
  max-width: 300px;
  min-width: 300px;
}

@media (max-width: 768px) {
  .cheatcard-list {
    flex-direction: column;
    align-items: center;
  }

  .cheatcard-list > * {
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .cheatcard-list > *:last-child {
    margin-bottom: 0;
  }
}

/* Default styles for large screens */
.ad-container {
  max-width: 800px;
  width: 100%;
}

@media (max-width: 1200px) {
  .ad-container {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .ad-container {
    max-width: 90%;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .ad-container {
    margin-top: 60px;
    max-width: 70%;
    padding: 0 5px;
  }
}

.h1-cheatid {
  display: flex;
  justify-content: center;
}
</style>
