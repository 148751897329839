<template>
  <div class="faq-container">
    <div class="faq-section">
      <h2>{{ firstSection.title }}</h2>
      <div class="faq-accordion">
        <div
          v-for="(faq, index) in firstSection.faqs"
          :key="index"
          class="faq-item"
          @click="toggleFaq('first', index)"
        >
          <div class="faq-question">
            <h3>{{ faq.question }}</h3>
            <span :class="{ rotate: firstActiveIndex === index }">▼</span>
          </div>
          <div v-if="firstActiveIndex === index" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Second Section -->
    <div class="faq-section">
      <h2>{{ secondSection.title }}</h2>
      <div class="faq-accordion">
        <div
          v-for="(faq, index) in secondSection.faqs"
          :key="index"
          class="faq-item"
          @click="toggleFaq('second', index)"
        >
          <div class="faq-question">
            <h3>{{ faq.question }}</h3>
            <span :class="{ rotate: secondActiveIndex === index }">▼</span>
          </div>
          <div v-if="secondActiveIndex === index" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="faq-section">
      <h2>{{ thirdSection.title }}</h2>
      <div class="faq-accordion">
        <div
          v-for="(faq, index) in thirdSection.faqs"
          :key="index"
          class="faq-item"
          @click="toggleFaq('third', index)"
        >
          <div class="faq-question">
            <h3>{{ faq.question }}</h3>
            <span :class="{ rotate: thirdActiveIndex === index }">▼</span>
          </div>
          <div v-if="thirdActiveIndex === index" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstActiveIndex: null,
      secondActiveIndex: null,
      thirdActiveIndex: null,
      firstSection: {
        title: "General Information",
        faqs: [
          {
            question:
              "What types of cheats and torrents do you offer at udhacks.com?",
            answer:
              "We offer a variety of free, secure cheats and torrents for numerous popular video games. These include gameplay enhancements, cosmetic changes, and more to improve your gaming experience.",
          },
          {
            question: "Are your cheats safe to use?",
            answer:
              "Yes, safety is our top priority. All cheats available on UDhacks undergo rigorous testing to ensure they are secure and undetectable. We use advanced methods to protect against malware and ensure compatibility with your game. While we strive to provide cheats that minimize risk, we advise users to always follow best practices and stay updated with any game or cheat-related changes to avoid potential issues.",
          },
          {
            question: "Do I need to pay for any cheats or torrents?",
            answer:
              "No, currently all cheats and torrents on our site are available for free. You can download and use them without any cost.",
          },
        ],
      },
      secondSection: {
        title: "Downloading and Support",
        faqs: [
          {
            question: "How do I download and install cheats from UDhacks?",
            answer:
              "To download and install cheats, simply select the cheats you want from our list, follow the straightforward instructions provided, and you’ll be ready to enhance your gameplay in just a few clicks.",
          },

          {
            question: "How can I get help if I encounter issues with a cheat?",
            answer:
              "If you encounter issues with a cheat, you can contact our support team through our Discord server. Provide detailed information about the problem you’re facing, and we will assist you in resolving it as quickly as possible.",
          },
          {
            question: "How do I join your Discord community?",
            answer:
              "To join our Discord community, visit our Discord invite link provided on our website. Click on the link, and you'll be directed to our Discord server where you can join the community, interact with other users, and get real-time support.",
          },
        ],
      },
      thirdSection: {
        title: "Community and Safety",
        faqs: [
          {
            question:
              "Is there a risk of getting banned for using your cheats?",
            answer:
              "While we strive to provide cheats that minimize risk, using cheats always carries some level of risk. It’s important to use them responsibly and be aware of the potential consequences. We recommend following all best practices and staying updated with game or cheat-related changes to reduce the likelihood of being banned.",
          },
          {
            question: "Can I contribute or suggest new cheats for UDhacks?",
            answer:
              "Yes, we welcome contributions and suggestions for new cheats. You can reach out to us through our Discord community to share your ideas and suggestions with our team.",
          },
        ],
      },
    };
  },
  methods: {
    toggleFaq(section, index) {
      if (section === "first") {
        this.firstActiveIndex = this.firstActiveIndex === index ? null : index;
      } else if (section === "second") {
        this.secondActiveIndex =
          this.secondActiveIndex === index ? null : index;
      } else if (section === "third") {
        this.thirdActiveIndex = this.thirdActiveIndex === index ? null : index;
      }
    },
  },
};
</script>

<style scoped>
.faq-container {
  max-width: 1300px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.faq-section {
  margin-bottom: 40px;
}

.faq-section h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: black;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 40px;
}

.faq-accordion {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-item:hover {
  background-color: #f5f5f5;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  margin: 0;
  font-size: 18px;
  color: black;
}

.faq-answer {
  margin-top: 12px;
  padding-top: 8px;
  font-size: 16px;
  color: #333;
  line-height: 2.2;
  border-top: 1px solid #ddd;
}
@media (max-width: 768px) {
  .faq-question h3 {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 15px;
    line-height: 1.7;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .faq-question h3 {
    font-size: 15px;
  }

  .faq-answer {
    font-size: 14px;
    line-height: 1.6;
    padding: 10px;
  }
}
span {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}
</style>
